<script setup lang="ts">
import { type ILoginForm } from '~/types/ILoginForm'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

useSeoMeta({
  title: 'Вход | Qummy',
  ogTitle: 'Вход | Qummy',
  twitterTitle: 'Вход | Qummy'
})
useHead({
  htmlAttrs: {
    lang: 'ru'
  }
})

const isDev = useIsDev()
const router = useRouter()
const { signIn } = useAuth()
const form = reactive<ILoginForm>({
  username: '',
  password: ''
})

const validationRules = {
  username: { email, required },
  password: { required }
}

const v$ = useVuelidate(validationRules, form)

const submit = () => signIn(form, { callbackUrl: '/auth/counterparty' })
</script>

<template>
  <h1 class="text-white text-2xl mb-6 sm:mb-[25px]">{{ $t('Login') }}</h1>
  <form class="w-full" @submit.prevent="submit">
    <div class="flex flex-col text-left">
      <InputText
        id="username"
        name="username"
        v-model="form.username"
        @blur="v$.username.$touch"
        :placeholder="$t('Email')"
        class="w-full px-5 py-4 text-white bg-transparent enabled:focus:border-amber-50 enabled:hover:border-amber-50 enabled:hover:ring-2 enabled:hover:ring-white enabled:focus:ring-2 enabled:focus:ring-white enabled:placeholder-amber-50"
      />
      <span v-for="({ $message }, index) in v$.username.$errors" class="ml-5 mt-0.5 text-red-700" :key="index">
          {{ $t($message) }}
        </span>
    </div>
    <div class="flex flex-col mt-4 text-left">
      <Password
        id="password"
        class="w-full"
        :feedback="false"
        v-model="form.password"
        @blur="v$.password.$touch"
        :placeholder="$t('Password')"
        input-class="px-5 py-4 text-white bg-transparent enabled:focus:border-amber-50 enabled:hover:border-amber-50 enabled:hover:ring-2 enabled:hover:ring-white enabled:focus:ring-2 enabled:focus:ring-white enabled:placeholder-amber-50"
      />
      <span v-for="({ $message }, index) in v$.password.$errors" class="ml-5 mt-0.5 text-red-700" :key="index">
          {{ $t($message) }}
        </span>
    </div>
    <a v-if="isDev" class="mt-3 sm:mt-4 underline inline-block" href="#">{{ $t('Forgot login or password?') }}</a>
    <Button
      @click="submit"
      :disabled="v$.$invalid"
      loading-icon="pi pi-plus"
      class="w-full text-white px-5 py-4 mt-3 sm:mt-4">
      {{ $t('Sign in') }}
    </Button>
  </form>
</template>

<style scoped>

</style>
